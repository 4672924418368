import React, { useMemo } from 'react';
import './App.less';
import { Button, Layout } from "antd";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import Home from "./pages/home";
import DetailWithSideBar from "./pages/detail";
import History from "./pages/history";
import FootHeadmap from './pages/foot-heatmap';

const { Header, Content, Footer } = Layout

function App() {
    const loc = useLocation()
    const nav = useNavigate()

    const inHeatmap = useMemo(() => {
        if (loc.pathname.indexOf('heatmap') != -1) {
            return 1
        }
        if(loc.pathname === '/') {
            return 2
        }
        return 0
    }, [loc.pathname])

    return (
        <Layout style={{ height: "100%" }}>
            <Header style={{width: '100%', textAlign: 'center' }}>{/*style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}*/}
                {/*{*/}
                {/*    inHeatmap ? */}
                {/*        inHeatmap === 1 ? (*/}
                {/*            <Button onClick={() => nav('/')}>返回首页</Button>*/}
                {/*        ) : (*/}
                {/*            <Button onClick={() => nav('/foot/heatmap')}>热力图</Button>*/}
                {/*        )*/}
                {/*        : <div />*/}
                {/*}*/}
                
                <h1 style={{ color: 'white', display: 'inline-block', fontSize: '2.5em' }}>multichannel capacitance system</h1>
                <div />
            </Header>
            <Content className="site-layout">
                <div className="site-layout-background" style={{ padding: 24, height: '100%', marginTop: 0 }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="devices/:mac" element={<DetailWithSideBar />} />
                        <Route path="devices/:mac/history" element={<History />} />
                        <Route path="foot/heatmap" element={<FootHeadmap />} />
                    </Routes>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>powered by <a href='https://www.scbjtznkj.com' >Sichuan Beijiate Intelligent Technology Co., Ltd</a> </Footer>
        </Layout>
    );
}

export default App;
