import {useRef, useEffect} from "react"
import {ORIGIN_WIDTH, ORIGIN_HEIGHT, drawHeatmap} from "./index"
import left from "../../static/l.png"

interface IProps {
    points: Array<number>
    mirror?: boolean
    width?: number
    height?: number
}

const ps = new Map(
    [[17, { x: 290, y: 400, weight: 0, }], [14, { x: 355, y: 350, weight: 0, }], [11, { x: 440, y: 325, weight: 0, }], [8, { x: 520, y: 310, weight: 0, }],
    [3, { x: 260, y: 600, weight: 0, }], [6, { x: 340, y: 550, weight: 0, }], [23, { x: 450, y: 530, weight: 0, }], [20, { x: 580, y: 580, weight: 0, }],
    [1, { x: 220, y: 800, weight: 0, }], [2, { x: 320, y: 750, weight: 0, }], [5, { x: 460, y: 740, weight: 0, }], [26, { x: 600, y: 810, weight: 0, }],
    [22, { x: 220, y: 1000, weight: 0, }], [25, { x: 300, y: 980, weight: 0, }], [4, { x: 460, y: 1000, weight: 0, }], [7, { x: 560, y: 1000, weight: 0, }],
    [19, { x: 230, y: 1200, weight: 0, }], [28, { x: 390, y: 1230, weight: 0, }],
    [16, { x: 250, y: 1400, weight: 0, }], [13, { x: 410, y: 1430, weight: 0, }],
    [10, { x: 255, y: 1650, weight: 0, }], [27, { x: 400, y: 1645, weight: 0, }], [9, { x: 500, y: 1645, weight: 0, }],
    [24, { x: 260, y: 1900, weight: 0, }], [18, { x: 420, y: 1900, weight: 0, }], [12, { x: 500, y: 1900, weight: 0, }],
    [21, { x: 320, y: 2100, weight: 0, }], [15, { x: 530, y: 2100, weight: 0, }],]
)

//补点前缀1 两个点的序号合并
const replenish: Array<string> = [
    '17-3',
    '8-20',
    '3-1',
    '17-14',
    '11-23',
    '26-7',
    '20-26',
    '5-26',
    '1-22',
    '19-16',
    '28-13',
    '22-19',
    '7-28',
    '5-25',
    '21-15',
    '24-21',
    '12-15',
]

const Left = ({points, mirror = false, width, height}: IProps) => {
    const ref = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        if (!ref || !ref.current) {
            return
        }

        if (!points || points.length < 1) {
            return
        }
        let p = new Map(ps.entries())

        for (let i = 0; i<points.length; i++) {
            let ite = p.get(i+1)
            if (ite) {
                p = p.set(i+1, {...ite, weight: points[i]})
            }
        }

        for (let item of replenish) {
            let pIDs = item.split("-")
            let p1 = p.get(Number(pIDs[0]))
            let p2 = p.get(Number(pIDs[1]))
            let weight = ((p1?.weight || 0) + (p2?.weight || 0))/2
            p = p.set(Number(`10${pIDs[0]}${pIDs[1]}`), {
                x: ((p1?.x || 0)+(p2?.x || 0))/2,
                y: ((p1?.y || 0)+(p2?.y || 0))/2,
                weight: weight
            })
        }

        let handle = window.requestAnimationFrame(() => {
            if (ref.current) {
                let ctx = ref.current.getContext('2d')
                if (ctx) {
                    ctx.resetTransform()
                    if (mirror) {
                        ctx.translate((ORIGIN_WIDTH)/2-1, 0)
                        ctx.scale(-(ref.current.width/ORIGIN_WIDTH), ref.current.height/ORIGIN_HEIGHT)
                    } else {
                        ctx.scale(ref.current.width/ORIGIN_WIDTH, ref.current.height/ORIGIN_HEIGHT)
                    }
                    let img = new Image()
                    img.src = left
                    img.onload = () => {
                        // let tempCanvas = document.createElement('canvas')
                        // tempCanvas.width = ORIGIN_WIDTH
                        // tempCanvas.height = ORIGIN_HEIGHT
                        // let c = tempCanvas.getContext('2d') as CanvasRenderingContext2D
                        // c.drawImage(drawHeatmap(ps), 0, 0)
                        // c.drawImage(img, 0, 0)

                        // ctx?.save()
                        ctx?.clearRect(0,0, ORIGIN_WIDTH, ORIGIN_HEIGHT)
                        ctx?.drawImage(drawHeatmap(p), 0, 0)
                        ctx?.drawImage(img, 0, 0)
                        // ctx?.resetTransform()
                        // ctx?.restore()

                        // ctx?.save()
                        // ctx?.clearRect(0,0,ORIGIN_WIDTH,ORIGIN_HEIGHT)
                        // ctx?.restore()
                    }
                }
            }
        })
        let handler = () => {
            window.cancelAnimationFrame(handle)
            document.removeEventListener('visibilitychange', handler)
        }
        document.addEventListener('visibilitychange', handler)
    }, [points, ref.current])

    return (
        <canvas ref={ref} width={`${width || 420}px`} height={`${height || 1140}px`}></canvas>
    )
}

export default Left