import {Card} from "antd";
import "./index.less"
import Label from "../../components/label";
import {useEffect} from "react";
import useWsCtrl from "../../hooks/websocket/ctrl";
import { device as IDevice } from "../../hooks/websocket/ctrl";

const Home = () => {
    const {create, close, devices} = useWsCtrl()

    useEffect(() => {
        document.title = 'Console'
        create()
    }, [])

    const goDevice = (mac: string) => {
        window.open(`${process.env.PUBLIC_URL}/devices/${mac}`, "_blank")
    }

    // useEffect(() => {
    //     console.log(devices)
    // }, [devices])

    return (
        <div style={{display: "flex", flexWrap: "wrap", alignContent: "space-around"}}>
            {
                Array.from<IDevice>(devices.values()).map((item, index) => (
                    <div key={index} className="card-wrapper">
                        <Card onClick={() => goDevice(item.mac)} size="small" className="card" bodyStyle={{display: 'flex',flexDirection: 'column', height: '100%'}}>
                            <div className="head">{item.name || item.mac}</div>
                            <div className="content" />
                            <div className="foot">
                                <Label text={`battery${item.battery}%`} style={{marginRight: '5px'}} />
                                {
                                    item.status ? (
                                        <Label text="connected" backgroundColor="green" style={{marginRight: '5px'}} />
                                    ) : <Label text="connection dropped" backgroundColor="red" />
                                }
                            </div>
                        </Card>
                    </div>
                ))
            }
        </div>
    )
}

export default Home