
const unit: Array<string> = [
    "pF",
    "nF",
    "μF",
    "mF",
    "F"
]

export const unitTrans = (pf: string|number): string => {
    if (pf === '-') {
        return '~'
    }
    let index = 0
    let p:number = typeof pf !== 'number' ? parseInt(pf) : parseInt(pf.toString())

    while (p >= 1000 && index < 4) {
        p = (p / 1000)
        index++
    }
    pf = p.toFixed(2) + unit[index]
    return pf
}