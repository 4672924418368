import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const wsBase = ():string|void => {
    if (BASE_URL) {
        if (BASE_URL.indexOf("https://") !== -1) {
            return `wss://${BASE_URL.replace("https://", "")}`
        } else if (BASE_URL.indexOf("http://") !== -1) {
            return `ws://${BASE_URL.replace("http://", "")}`
        } else {
            console.warn("base url is invalid")
        }
    }
}

export const WS_BASE = wsBase();

const instance = axios.create({
    baseURL: BASE_URL
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
    return config;
})

instance.interceptors.response.use((response:AxiosResponse) => {
    return response
})

export default instance