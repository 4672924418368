import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client"

const container = document.getElementById('root')
const root = createRoot(container as Element)
root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* <React.StrictMode> */}
            <App />
        {/* </React.StrictMode> */}
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
