import { message, Select } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Left } from "../../components/foot"
import useWsCtrl from "../../hooks/websocket/ctrl"
import useWsData from "../../hooks/websocket/data"

interface dev {
    name: string
    mac: string
}

// const rand = () => {
//     return Math.random() * 200000
// }

const FootHeadmap = () => {
    // const [points, setPoints] = useState<Array<number>>([])
    const {create, close, devices} = useWsCtrl()
    const [selectedMac, setSelectedMac] = useState<Array<string|undefined>>([undefined, undefined])
    const left = useWsData()
    const right = useWsData()

    useEffect(() => {
        create()
    }, [])

    const devs = useMemo(() => {
        let devs: Array<dev> = new Array()
        devices.forEach((item) => {
            if(item.status) {
                devs.push({
                    name: item.name,
                    mac: item.mac,
                })
            }
        })

        return devs
    }, [devices])

    useEffect(() => {
        if (!devs.find((item) => selectedMac[0] === item.mac)) {
            selectedMac[0] = undefined
            left.useMac('')
        }
        if (!devs.find((item) => selectedMac[1] === item.mac)) {
            selectedMac[1] = undefined
            right.useMac('')
        }
    }, [devs])

    // useEffect(() => {
    //     let handle = setInterval(() => {
    //         // setPoints([
    //         //     0,0,0,0,
    //         //     0,0,0,rand(),
    //         //     0,0,0,0,
    //         //     0,0,0,0,
    //         //     0,0,0,rand(),
    //         //     0,0,0,0,
    //         //     0,0,0,0,
    //         // ])
    //         setPoints([
    //             rand(),rand(),rand(),rand(),
    //             rand(),rand(),rand(),rand(),
    //             rand(),rand(),rand(),rand(),
    //             rand(),rand(),rand(),rand(),
    //             rand(),rand(),rand(),rand(),
    //             rand(),rand(),rand(),rand(),
    //             rand(),rand(),rand(),rand(),
    //         ])
    //     }, 1000)
    //     return () => {
    //         clearInterval(handle)
    //     }
    // }, [])

    // 生成下拉选择组件onChange处理函数，index只有两个值：0左 1右
    const selectChange = useCallback((index: number) => {
        return (mac: string) => {
            //判重
            if(selectedMac.indexOf(mac) != -1 && mac !== undefined) {
                message.warning('该设备已经被选择')
                return
            }

            //新连接
            index ? right.useMac(mac) : left.useMac(mac)

            selectedMac[index] = mac

            setSelectedMac(selectedMac.slice())
        }
    }, [selectedMac])

    const selectClear = (index: number) => {
        return () => {
            selectedMac[index] = ''
            setSelectedMac(selectedMac.slice())
        }
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <div 
                style={{
                    display: 'flex', 
                    flexDirection: "column", 
                    width: 420,
                    height: 1140, 
                    alignItems: 'center', 
                    justifyContent: 'space-between'
                }}
            >
                <Select
                    placeholder='请选择'
                    style={{ width: 200 }}
                    allowClear
                    options={devs.map(item => ({label: item.name || item.mac, value: item.mac}))}
                    onChange={selectChange(0)}
                    onClear={selectClear(0)}
                    value={selectedMac[0]}
                />
                <Left width={400} height={1100} points={left.data.yData.map(item => Number(item[item.length - 1]) || 0)}></Left>
            </div>
            <div 
                style={{
                    display: 'flex', 
                    flexDirection: "column", 
                    width: 420,
                    height: 1140, 
                    alignItems: 'center', 
                    justifyContent: 'space-between'
                }}
            >
                <Select
                    placeholder='请选择'
                    style={{ width: 200 }}
                    allowClear
                    options={devs.map(item => ({label: item.name || item.mac, value: item.mac}))}
                    onChange={selectChange(1)}
                    onClear={selectClear(1)}
                    value={selectedMac[1]}
                />
                <Left mirror={true} width={400} height={1100} points={right.data.yData.map(item => Number(item[item.length - 1]) || 0)}></Left>
            </div>
        </div>
    )
}

export default FootHeadmap