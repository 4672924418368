import {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import * as echarts from "echarts";
import {v1 as uuidv1} from "uuid";
import {unitTrans} from "../../utils/utils";

interface DeviceChartMiniProps {
    title: string
    index: number
    xData: Array<number>
    yData: Array<string>
    selected?: boolean
    style?: CSSProperties
    onSelectedChange?: (selected: boolean, index: number) => void
}

const DeviceChartMini = ({title, index, style, xData, yData, selected = false, onSelectedChange}: DeviceChartMiniProps) => {
    const id = uuidv1().toString()
    const [select, setSelect] = useState(false)
    const [chart, setChart] = useState<any>(null)

    const mergeStyle = useMemo<CSSProperties>(() => {
        let s: CSSProperties = {
            ...style,
            boxSizing: 'border-box',
            border: '2px solid white' //占位
        }
        if (select) {
            s.borderColor = 'blue'
        } else {
            s.borderColor = 'white'
        }
        return s
    }, [select, style])

    const toggle = useCallback(() => {
        setSelect(!select)
        if (onSelectedChange) {
            onSelectedChange(!select, index)
        }
    }, [select, onSelectedChange])

    useEffect(() => {
        setSelect(selected || false)
    }, [selected])

    useEffect(() => {
        let option = {
            backgroundColor: 'white',
            title: {
                text: title
            },
            grid: {
                bottom: '15%',
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params: any) {
                    if (params) {
                        let param = params[0];
                        return unitTrans(param.value);
                    } else {
                        return ''
                    }
                },
                axisPointer: {
                    animation: false
                }
            },
            xAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                data:[],
                axisLabel: {
                    show: false
                    // formatter: (value: any, index: number) => {
                    //     console.log(value)
                    //     return ""
                    // }
                }
            },
            yAxis: {
                type: 'value',
                // splitLine: {
                //     show: false
                // },
                boundaryGap: [0, '20%'],
                axisLabel: {
                    show: false
                }
            },
            series: [
                {
                    name: `通道${index + 1}`,
                    type: 'line',
                    showSymbol: false,
                    data: []
                }
            ]
        }
        let chart = echarts.init(document.getElementById(id) as HTMLElement, undefined, {renderer: 'svg'})
        chart.setOption(option)
        setChart(chart)
    }, [])

    useEffect(() => {
        if (chart) {
            chart.setOption({
                title: {
                    text: `${title} ${unitTrans(yData[yData.length - 1])}`
                },
                xAxis: {
                    data: xData
                },
                series: [
                    {
                        data: yData
                    }
                ]
            })
        }
    }, [xData, yData])

    return (
        <div onClick={toggle} id={id} style={mergeStyle} />
    )
}

export default DeviceChartMini