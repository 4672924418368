import {clean, start, stop} from "../../api";
import {message} from "antd";
import { useCallback, useState, useEffect } from "react";
import { ACTION_CLEAR } from "../../workers/data";
import {WS_BASE} from "../../axios";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import dataWorker from 'worker-loader?filename=data.worker.js!../../workers/data';

const useWsData = (): {
    useMac: (mac: string) => void,
    begin: () => void,
    end: () => void,
    clear: () => Promise<boolean>,
    data: {
        startTime: number,
        xData: Array<number>,
        yData: Array<Array<string>>,
        recording: boolean,
        record_count: number,
    },
} => {
    const [worker, setWorker] = useState<any>(null);
    const [data, setData] = useState<{
        startTime: number,
        xData: Array<number>,
        yData: Array<Array<string>>,
        recording: boolean,
        record_count: number,
    }>({
        startTime: 0,
        xData: [],
        yData: [],
        recording: false,
        record_count: 0
    })
    const [mac, setMac] = useState('')

    const useMac = (mac: string) => {
        setMac(mac)
    }

    const begin = useCallback(() => {
        start(mac as string).then(
            () => {
                message.success("starting")
            },
            err => {
                message.error(`start failed: ${err.response.data.msg}`)
            }
        )
    }, [mac])

    const end = useCallback(() => {
        stop(mac as string).then(
            () => {
                message.success("stopping")
            },
            err => {
                message.error(`stop failed: ${err.response.data.msg}`)
            }
        )
    }, [mac])

    const clear: () => Promise<boolean> = useCallback(() => {
        return new Promise((resolve, reject) => {
            clean(mac as string).then(
                () => {
                    message.success("clearing")
                    if (worker) {
                        worker.postMessage({
                            action: ACTION_CLEAR,
                        })
                    }
                    setData({...data, record_count: 0})
                    resolve(true)
                },
                err => {
                    message.error(`clear failed: ${err.response.data.msg}`)
                    reject(err)
                }
            )
        })
    }, [mac, worker, data])

    useEffect(() => {
        if (mac) {
            if (worker) {
                worker.terminate()
            }

            let myWorker = new dataWorker()
            setWorker(myWorker)

            myWorker.onmessage = (m: MessageEvent) => {
                setData(m.data)
            }

            myWorker.postMessage({
                url: `${WS_BASE}devices/${mac}/connect`,
            })
            return () => {
                myWorker.terminate()
            }
        }
    }, [mac])

    return {useMac, begin, end, clear, data}
}

export default useWsData