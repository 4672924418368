import {useState} from 'react'
import {WS_BASE} from "../../axios";

const CTRL_CREATE = 1
const CTRL_DEL = 2
const CTRL_OFFLINE = 3

export interface device {
    name: string
    mac: string
    battery: number
    status: number
    startTime: number
}

let ws: WebSocket|null = null

const useWsCtrl = () => {
    const [devices, setDevices] = useState<Map<string, device>>(new Map<string, device>())

    const close = () => {
        if(ws) {
            ws.close()
            ws = null
        }
    }

    const create = () => {
        close()
        ws = new WebSocket(`${WS_BASE}ctrl/connect`)
        ws.onmessage = (msg: any) => {
            msg = JSON.parse(msg.data)
            let dev: device|undefined
            switch (msg.action) {
                case CTRL_CREATE:
                    dev = devices.get(msg.mac)
                    if (dev) {
                        dev.battery = msg.battery
                        dev.status = 1
                    } else {
                        devices.set(msg.mac, {
                            name: msg.name,
                            mac: msg.mac,
                            battery: msg.battery,
                            status: 1,
                            startTime: msg.start_time
                        })
                    }
                    break
                case CTRL_OFFLINE:
                    dev = devices.get(msg.mac)
                    if (dev) {
                        dev.status = 0
                    } else {
                        devices.set(msg.mac, {
                            name: msg.name,
                            mac: msg.mac,
                            battery: msg.battery,
                            status: 0,
                            startTime: msg.start_time
                        })
                    }
                    break
                case CTRL_DEL:
                    devices.delete(msg.mac)
                    break
            }
            setDevices(new Map(devices.entries()))
        }
    }

    return {create, close, devices}
}

export default useWsCtrl