import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import * as echarts from "echarts";
import {Button} from "antd";
import {BASE_URL} from "../../axios";
import {loadHistory} from "../../api";
import {EChartsType} from "echarts";
import {unitTrans} from "../../utils/utils";
import moment from "moment";

interface HistoryParams {
    mac: string
}

const History = () => {
    const params = useParams<Record<keyof HistoryParams, string>>()
    const container = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>(500)
    const [height, setHeight] = useState<number>(400)
    const [chart, setChart] = useState<EChartsType|null>(null)
    const nav = useNavigate()

    const download = () => {
        window.open(`${BASE_URL}devices/${params.mac}/export`, '_blank')
    }

    useEffect(() => {
        document.title = 'historical data'
        let option = {
            backgroundColor: 'white',
            title: {
                text: "historical data"
            },
            grid: {
                right: 20,
                left: 60,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    animation: false
                },
                valueFormatter: function (value: string|number) {
                    return unitTrans(value)
                },
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                {
                    start: 0,
                    end: 100,
                    bottom: 10,
                }
            ],
            xAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                },
                boundaryGap: [0, '20%'],
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '20%'],
                axisLabel: {
                    formatter: (value: string) => {
                        return unitTrans(value)
                    }
                },
            },
            legend: {
                data: [],
            },
            series: [],
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none',
                        title: {
                            zoom: 'zoom',
                            back: 'restore'
                        }
                    },
                    saveAsImage: {
                        title: 'save picture'
                    },
                },
                brush: {
                    xAxisIndex: 'all',
                    brushLink: 'all',
                    outOfBrush: {
                        colorAlpha: 0.1
                    }
                },
            }
        }
        let chart = echarts.init(document.getElementById("history") as HTMLElement)
        chart.setOption(option)
        setChart(chart)

        loadHistory(params.mac as string).then(
            res => {
                let selected: any = {}
                res.data.data.y.map((item: Array<any>, index: number) => `channel${index + 1}`).forEach((item:string) => {
                    selected[item] = false
                })
                chart.setOption({
                    xAxis: {
                        data: res.data.data.x.map((item: number) => {
                            let duration = moment.duration(moment(item).diff(moment(res.data.data.x[0])))
                            return `${duration.minutes()}:${duration.seconds()}'${duration.milliseconds()}"`
                        })
                    },
                    legend: {
                        top: 30,
                        type: 'scroll',
                        data: res.data.data.y.map((item: Array<any>, index: number) => `channel${index + 1}`),
                        selected: selected
                    },
                    series: res.data.data.y.map((item: Array<any>, index: number) => ({
                        name: `channel${index + 1}`,
                        type: 'line',
                        connectNulls: true,
                        emphasis: {
                            focus: 'series',
                            lineStyle: {
                                width: 4
                            }
                        },
                        data: item
                    })),
                })
            }
        )
    }, [])

    useEffect(() => {
        if (container) {
            setWidth(container.current?.clientWidth || container.current?.offsetWidth || container.current?.scrollWidth || 0)
            setHeight(container.current?.clientHeight || container.current?.offsetHeight || container.current?.scrollHeight || 0)
        }
    }, [])

    useEffect(() => {
        if (chart) {
            chart.resize({
                width: width,
                height: height * 0.97,
            })
        }
    }, [width, height, chart])

    return (
        <div ref={container} style={{height: '100%'}}>
            <Button type='primary' onClick={() => {nav(-1)}}>back</Button>
            <Button type='primary' onClick={download}>export</Button>
            <div id="history" style={{width: width, height: height * 0.9}}></div>
        </div>
    )
}

export default History