import Left from './left'

export {
    Left
}
// 扩散半径
export const R = 250

// 量程
const MAX = 20000

// 原始宽度
export const ORIGIN_WIDTH=802
//原始高度
export const ORIGIN_HEIGHT=2325

//点数据
export interface point {
    x: number
    y: number
    weight: number
}

// 色带
export const paint = () => {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    let gradientConfig = {
        '0': 'rgba(255,255,255,0.1)',
        '0.3': 'rgba(255,255,0,0.3)',
        '0.5': 'rgba(255,255,0,0.5)',
        '0.7': 'rgba(255,0,0,0.7)',
        '0.8': 'rgba(255,0,0,0.8)',
        '0.9': 'rgba(255,0,0,0.9)',
        '1.0': 'rgba(255,0,0,1)'
    }
    canvas.width = 256
    canvas.height = 1
    let gradient = ctx.createLinearGradient(0, 0, 256, 1)
    for (let key in gradientConfig) {
        //@ts-ignore
        gradient.addColorStop(key, gradientConfig[key])
    }
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, 256, 1)
    let i = ctx.getImageData(0, 0, 256, 1) as ImageData
    return i.data
}

// 画点
export const point = (ctx: CanvasRenderingContext2D, x: number, y: number, weight: number) => {
    ctx.globalAlpha = weight / MAX
    ctx.beginPath()
    ctx.arc(x, y, R, 0, Math.PI * 2)
    ctx.closePath()
    let gradient = ctx.createRadialGradient(x, y, 0, x, y, R)
    gradient.addColorStop(0, 'rgba(0,0,0,1)')
    // gradient.addColorStop(0.8, 'rgba(0,0,0,0)')
    // gradient.addColorStop(0.9, 'rgba(0,0,0,0)')
    gradient.addColorStop(1, 'rgba(0,0,0,0)')
    ctx.fillStyle = gradient
    ctx.fill()
}

// 画热力图
export const drawHeatmap = (points: Map<number, any>): HTMLCanvasElement => {
    let canvas = document.createElement('canvas')
    canvas.width = ORIGIN_WIDTH
    canvas.height = ORIGIN_HEIGHT

    let ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    points.forEach((value) => {
        point(ctx as CanvasRenderingContext2D, value.x, value.y, value.weight)
    })
    ctx.globalAlpha = 1

    let colorP = paint()
    let i = ctx.getImageData(0, 0, canvas.width, canvas.height) as ImageData
    let data = i.data
    for (let i=3; i<(data?.length || 0); i+=4) {
        let a = data[i]
        let offset = a * 4
        if (!offset) {
            continue
        }

        data[i-3] = colorP[offset]
        data[i-2] = colorP[offset+1]
        data[i-1] = colorP[offset+2]
    }
    ctx.putImageData(i, 0, 0)
    return canvas
}