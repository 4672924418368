import './index.less'
import {CSSProperties, useMemo} from "react";

interface LabelProps {
    text: string
    backgroundColor?: string
    color?: string
    style?: CSSProperties
}

const Label = ({text, style, backgroundColor = 'gray', color = 'white'}: LabelProps) => {
    const s = useMemo(() => {
        let deft = {
            borderColor: backgroundColor,
            backgroundColor: backgroundColor,
            color: color
        }
        if (style) {
            deft = {...deft, ...style}
        }
        return deft
    }, [style, backgroundColor, color])

    return (
        <div className="label" style={s}>{text}</div>
    )
}

export default Label