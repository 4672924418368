import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import DeviceChartMini from "../../components/device-chart/mini";
import "./index.less"
import * as echarts from "echarts";
import {EChartsType} from "echarts";
import {Button} from "antd";
import {unitTrans} from "../../utils/utils";
import moment from "moment";
import useWsData from "../../hooks/websocket/data";

interface IParams {
    mac: string
}

const DetailWithSideBar = () => {
    const params = useParams<Record<keyof IParams, string>>()
    const [selected, setSelected] = useState<Array<number>>([])
    const containerRef = useRef<HTMLDivElement|null>(null)
    const [chart, setChart] = useState<EChartsType>()
    const [containerWidth, setContainerWidth] = useState<number>(0)
    const [containerHeight, setContainerHeight] = useState<number>(0)
    const nav = useNavigate()

    const wsData = useWsData()

    const clear = useCallback(() => {
        wsData.clear().then(
            () => {
                if (chart) {
                    let options = chart.getOption() as any
                    options.xAxis[0].data = []
                    options.series = []
                    options.legend[0].data = []
                    chart.setOption(options, true)
                }
            }
        )
    }, [chart])

    const onSelectedChange = (state: boolean, index: number) => {
        let data = selected
        if (state) {
            data = [index]
            // data.push(index)
        } else {
            data = []
            // data = selected.filter(item => item !== index)
        }
        setSelected(data.slice())
    }

    useEffect(() => {
        document.title = `mac: ${params.mac}`
        if (containerRef) {
            let width = containerRef.current?.clientWidth || containerRef.current?.offsetWidth || containerRef.current?.scrollWidth || 0
            setContainerWidth(width)
            let height = containerRef.current?.clientHeight || containerRef.current?.offsetHeight || containerRef.current?.scrollHeight || 0
            setContainerHeight(height)
        }
        wsData.useMac(params.mac || '')
    }, [])

    useEffect(() => {
        if (containerHeight && containerWidth) {
            let option = {
                backgroundColor: 'white',
                title: {
                    text: "channel detail"
                },
                grid: {
                    bottom: 17,
                    right: 20,
                    left: 60,
                },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: function (value: string|number) {
                        return unitTrans(value)
                    },
                    axisPointer: {
                        animation: false
                    }
                },
                xAxis: {
                    type: 'category',
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        // formatter: (value: string, index: number) => {
                        //     let duration = moment.duration(moment(parseInt(value)).diff(moment(data.startTime || 0)))
                        //     return `${duration.minutes()}:${duration.seconds()}'${duration.milliseconds()}"`
                        // }
                    },
                    boundaryGap: [0, '20%'],
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '20%'],
                    axisLabel: {
                        formatter: (value: string) => {
                            return unitTrans(value)
                        }
                    },
                },
                legend: {
                    data: [],
                },
                series: []
            }
            let chart = echarts.init(document.getElementById("summary") as HTMLElement)
            chart.setOption(option)
            setChart(chart)
        }
    }, [containerWidth, containerHeight])

    useEffect(() => {
        if (chart) {
            let options = chart.getOption() as any
            if (selected.length < 1) {
                options.xAxis[0].data = []
                options.series = []
                options.legend[0].data = []
            } else if (wsData.data.recording) {
                let series: Array<any> = []
                selected.forEach(item => {
                    series.push({
                        name: `chan#${item + 1}`,
                        type: 'line',
                        showSymbol: true,
                        connectNulls: true,
                        emphasis: {
                            focus: 'series',
                            lineStyle: {
                                width: 4
                            }
                        },
                        animation: false,
                        data: wsData.data.yData[item]
                    })
                })
                options.xAxis[0].data = wsData.data.xData
                options.xAxis[0].axisLabel.formatter = (value: string) => {
                    let duration = moment.duration(moment(parseInt(value)).diff(moment(wsData.data.startTime || 0)))
                    return `${duration.minutes()}:${duration.seconds()}'${duration.milliseconds()}"`
                }
                options.series = series
                options.legend[0].data = series.map((item: any) => item.name)
            }
            chart.setOption(options, true)
        }
    }, [wsData.data, selected])

    return (
        <div style={{height: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                    <div>count of records: {wsData.data.record_count}</div>
                    <Button type="primary" onClick={wsData.begin}>start</Button>
                    <Button onClick={wsData.end}>pause</Button>
                    <Button onClick={clear}>clear</Button>
                    <Button onClick={() => nav(`/devices/${params.mac}/history`)}>historical data</Button>
                </div>
                <div ref={containerRef} style={{height: "100%", width: "100%"}}>
                    <div id="summary" style={{width: containerWidth, height: containerHeight}} />
                </div>
            </div>
            <div className="sidebar">
                {
                    wsData.data.yData?.map((yData, index) => {
                        return <DeviceChartMini
                            key={index}
                            title={`c${index+1}`}
                            xData={wsData.data.xData}
                            yData={yData}
                            index={index}
                            style={{width: 150, height: 150, flex: '0 0 auto'}}
                            selected={selected.indexOf(index) !== -1}
                            onSelectedChange={onSelectedChange}
                        />
                    })
                }
            </div>
        </div>
    )
}

export default DetailWithSideBar