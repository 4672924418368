import moment from 'moment'

export const ACTION_CLEAR = 'clear'

interface IData {
    startTime: number
    originX: Array<number>
    originY: Array<Array<string>>
}

interface workerMsg {
    url?: string
    action?: typeof ACTION_CLEAR
}

interface wsMsg {
    mac: string
    time: number
    chans: string //json字符串
    start_time: number
    record_count: number
    recording: boolean
}

let data: IData = {
    startTime: 0,
    originX: new Array<number>(),
    originY: []
}

onmessage = (message: MessageEvent<workerMsg>) => {
    if (message.data.url) {
        let ws = new WebSocket(message.data.url)
        ws.onmessage = (msg: MessageEvent<string>) => {
            let msgs:Array<wsMsg> = JSON.parse(msg.data)
            msgs.forEach(m => {
                data.startTime = m.start_time
                // if (m.start_time > m.time) {
                //     return
                // }
                data.originX.push(m.time)

                let chans: Array<string> = JSON.parse(m.chans)
                for (let index in chans) {
                    if (data.originY[index] === undefined) {
                        data.originY[index] = []
                    }
                    data.originY[index].push(chans[index])
                }
                if (data.originX.length >= 300) {
                    data.originX.shift()
                    data.originY.forEach(item => item.shift())
                }
            })

            // eslint-disable-next-line no-restricted-globals
            self.postMessage({
                startTime: data.startTime,
                xData: data.originX.slice(),
                yData: data.originY.map(item => item.slice()),
                record_count: msgs[0].record_count,
                recording: msgs[0].recording
            })
        }
    }
    if (message.data.action) {
        switch(message.data.action) {
            case ACTION_CLEAR:
                data = {
                    startTime: 0,
                    originX: [],
                    originY: []
                }
            break
        }
    }
}

export {}
